/**
 * Seo component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import * as React from 'react'
import Helmet from 'react-helmet'
import useSiteMetadata from '../hooks/use-site-metadata'

function Seo(props: SeoProps) {
  const site = useSiteMetadata()
  /*   const { pathname } = useLocation(); */
  const pathname = '/'
  const seo = {
    title: props.title || site.title,
    description: props.description || site.description,
    image: `${new URL(props.image || site.ogImage, site.siteUrl)}`,
    url: `${new URL(pathname, site.siteUrl)}`,
    lang: props.lang || site.lang,
    type: props.type,
    icon48: `${new URL('/icons/icon-48x48.png', site.siteUrl)}`,
    icon72: `${new URL('/icons/icon-72x72.png', site.siteUrl)}`,
    icon96: `${new URL('/icons/icon-96x96.png', site.siteUrl)}`
  }
  return (
    <Helmet title={seo.title} titleTemplate={`%s | ${site.name}`}>
      <html lang={seo.lang} />
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <meta name="image:alt" content={seo.description} />

      <link rel="canonical" href={seo.url} />
      <link rel="icon" type="image/png" sizes="48x48" href={seo.icon48} />
      <link rel="icon" type="image/png" sizes="96x96" href={seo.icon96} />

      <meta property="og:type" content={seo.type} />
      <meta property="og:site_name" content={site.name} />
      <meta property="og:title" content={seo.title} />
      <meta property="og:url" content={seo.url} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:image" content={seo.image} />
      <meta property="og:image:alt" content={seo.description} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:url" content={seo.url} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />
      <meta name="twitter:image:alt" content={seo.description} />
    </Helmet>
  )
}
interface SeoProps {
  title?: string
  description?: string
  image?: string
  lang?: string
  type?: 'website' | 'article'
  path?: string
}
Seo.defaultProps = {
  title: '',
  description: '',
  image: '',
  lang: '',
  type: 'website'
}
export default Seo
